<div class="roam-content-fill">
  <div class="flex-column-height">
    <div [class]="'roam-content-fill-body ' + layoutWidth()"
         [class.start-page]="startPage"
         [class.full-page]="fullPage"
    >

      <ng-content select="[header]"></ng-content>

      <div class="flex-1 mt-24">
        <ng-content select="[content]"></ng-content>
      </div>
    </div>

    <div>
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>

