import { Component, input, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: 'app-roam-layout-content-fill',
  templateUrl: './roam-layout-content-fill.component.html',
  styleUrls: ['./roam-layout-content-fill.component.scss']
})
export class RoamLayoutContentFillComponent {

  @Input()
  public startPage!: boolean;

  @Input()
  public fullPage!: boolean;

  layoutWidth = input<string>()
}
